export default class Mandat extends HTMLDivElement {

    constructor() {
        super();
    }

    connectedCallback() {

        $(document).ready(function () {
            var firstError = $('.field-validation-error:first');
            if (firstError.length) {
                $('html, body').animate({
                    scrollTop: firstError.offset().top - 150
                }, 1000);
                firstError.focus();
            }
        });

        $('#CopieRIB').change(function () {
            var fileName = $(this).val().split('\\').pop(); // R�cup�re le nom du fichier

            if (fileName != "") {
                var i = $(this).prev('label').clone();
                $(this).prev('label').text(fileName);
            }
        });
    }
}