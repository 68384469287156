export default class Inscription extends HTMLDivElement {

    constructor() {
        super();
        this.$input = this.querySelector('#btnAddDriverClose');
    }

    connectedCallback() {

        $(document).ready(function () {
            var firstError = $('.field-validation-error:first');
            if (firstError.length) {
                $('html, body').animate({
                    scrollTop: firstError.offset().top - 150
                }, 1000);
                firstError.focus();
            }
        });

        this.$input.addEventListener("click", function () {
            $('#Conducteur2_Email').val('');
            $('#Conducteur2_Prenom').val('');
            $('#Conducteur2_Nom').val('');
        });

        $('#ExtraitKBis').change(function () {
            var fileName = $(this).val().split('\\').pop(); // R�cup�re le nom du fichier

            if (fileName != "") {
                var i = $(this).prev('label').clone();
                $(this).prev('label').text(fileName);
            }
        });
    }
}