import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default class SiteHeader extends HTMLElement {

    constructor() {
        super();
        this.toggleNav = this.querySelector('[data-toggle-nav]');
        this.nav = this.querySelector('nav');
    }

    connectedCallback() {

        this.toggleNav.addEventListener('click', e => {
            if (this.classList.contains('nav-opened')) {
                this.classList.remove('nav-opened');
                clearAllBodyScrollLocks();
            } else {
                disableBodyScroll(this.nav);
                this.classList.add('nav-opened');
            }
        });

        window.addEventListener('resize', e =>{
            this.classList.remove('nav-opened');
            clearAllBodyScrollLocks();
        });

    }

}