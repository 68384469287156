import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyles } from "../utils/mapStyles";
import markerIcon from "../../img/map-marker.svg";
import markerIconActive from "../../img/map-marker-active.svg";
require("../../img/map-marker.svg");

export default class StationsMap extends HTMLElement {

    constructor() {
        super();

        this.markers = [];
        this.markersDatas = JSON.parse($('.js-map')[0].textContent.replace(",]", "]"));

    }

    connectedCallback() {

        loadGoogleMapsApi({ key: "AIzaSyCE2SMB-JZc58WLrsajryG9A3CwaqcFpSk" }).then((googleMaps) => {

            let bounds = new googleMaps.LatLngBounds();

            let map = new googleMaps.Map(this, {
                center: { lat: 48.111778, lng: -1.680260 },
                zoom: 12,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: true,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                styles: mapStyles
            });

            const infowindow = new google.maps.InfoWindow({
                content: "",
            });

            for (let i in this.markersDatas) {

                let latLngById = {
                    id: this.markersDatas[i].attr,
                    lat: parseFloat(this.markersDatas[i].lat.replace(',', '.')),
                    lng: parseFloat(this.markersDatas[i].lng.replace(',', '.'))
                }

                let icon;

                icon = {
                    anchor: new google.maps.Point(18, 50),
                    url: markerIcon
                };

                let marker = new google.maps.Marker({
                    position: latLngById,
                    title: this.markersDatas[i].name,
                    icon: icon
                });
                this.markers.push(marker);

                let contentString = `<div class="h4 mb-3">` + this.markersDatas[i].name + `</div>` + this.markersDatas[i].description + ``;

                marker.addListener('click', (e) => {
                    map.setCenter(marker.getPosition());
                    infowindow.setContent(contentString);
                    infowindow.open({
                        anchor: marker,
                        content: contentString,
                        map,
                        shouldFocus: false
                    });
                });

                bounds.extend(latLngById);
                marker.setMap(map);

            }

            map.fitBounds(bounds);

        }).catch(function (error) {
            console.error(error)
        });

    }

}

