import intlTelInput from 'intl-tel-input';

export default class IntlTelInput extends HTMLDivElement {

    constructor() {
        super();
        this.$input = this.querySelector('input');
    }

    connectedCallback() {

        const iti = intlTelInput(this.$input, {
            separateDialCode: true,
            initialCountry: "fr"
        });

        if ($('#DialCode').val() == '' || $('#CountryCode').val() == '') {
            $('#DialCode').val('+33');
            $('#CountryCode').val('fr');
        }

        this.$input.addEventListener("countrychange", function () {
            $('#DialCode').val(iti.getSelectedCountryData().dialCode);
            $('#CountryCode').val(iti.getSelectedCountryData().iso2);
        });

    }

}