export default class Tarifs extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        $('#formulaParticulier').on("click", function (e) {
            $('.js-formula-part').addClass("d-flex").removeClass("d-none");
            $('.js-formula-pro').hide();
            $('#formulaPro').removeClass("active");
            $('#formulaParticulier').addClass("active");
        });

        $('#formulaPro').on("click", function (e) {
            $('.js-formula-part').removeClass("d-flex").addClass("d-none");
            $('.js-formula-pro').show();
            $('#formulaPro').addClass("active");
            $('#formulaParticulier').removeClass("active");
        });

        if (this.hasAnchor('pro')) {
            $('#formulaPro').click();
        }
    }

    // Permet de savoir si l'url actuel a une ancre qui correspond
    hasAnchor(anchor) {
        return window.location.hash.indexOf(anchor) > -1;
    }
}

