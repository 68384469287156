export default class InscriptionSummary extends HTMLDivElement {

    constructor() {
        super();
        this.$input = this.querySelector('#btnAddDriver');
        this.cgvIsChecked = false;
        this.ppdpIsChecked = false;
        this.cgctcapIsChecked = false;
    }

    connectedCallback() {
        let self = this;

        $("#modalCode").hide();


        $("#cgv").change(function () {
            if (this.checked) {
                self.cgvIsChecked = true;
            }
            else {
                self.cgvIsChecked = false;
            }

            self.EnableSubmitButton();
        });

        $("#cg-ct-cap").change(function () {
            if (this.checked) {
                self.cgctcapIsChecked = true;
            }
            else {
                self.cgctcapIsChecked = false;
            }

            self.EnableSubmitButton();
        });

        $("#ppdp").change(function () {
            if (this.checked) {
                self.ppdpIsChecked = true;
            }
            else {
                self.ppdpIsChecked = false;
            }

            self.EnableSubmitButton();
        });

        $('#validate').click(function () {

            // Recup de l'antiforgerytoken
            var form = $('#__AjaxAntiForgeryForm');
            var token = $('input[name="__RequestVerificationToken"]', form).val();

            // on lance la generation du sms
            $.ajax({
                type: 'POST',
                url: '/Subscription/Subscription/GenerateSmsCodeAsync',
                data: {
                    __RequestVerificationToken: token
                },
                error: function () {
                    $('#messageCode').show();
                    $('#messageCode').addClass('text-danger')
                    $('#messageCode').text('Une erreur est survenue lors de l\'envoie du code.')
                },
                success: function (data) {
                    if (data.success == 0) {
                        $('#messageCode').show();
                        $('#messageCode').addClass('text-danger')
                        $('#messageCode').text(data.message)
                    }
                }
            });

            //open bootsrap modal
            $('#modalCode').modal({
                show: true
            });
        });

        // au clic sur envoyer dans la modale pour valider le code
        $('#validationSmsCode').click(function () {

            $.ajax({
                type: 'POST',
                url: '/Subscription/Subscription/ValidationSmsCode',
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify({ code: $('#code').val() }),
                error: function () {
                },
                success: function (data) {
                    if (data.success == 1) {
                        $('#formInscription').submit();
                    } else if (data.success == 2) {
                        window.location.href = "/";
                    } else {
                        $('#lienResendCode').show();
                        $('#messageCode').hide();

                    }
                }
            });
        });

        // clic sur le lien de resend
        $('#lienResendCode').click(function () {

            // Recup de l'antiforgerytoken
            var form = $('#__AjaxAntiForgeryForm');
            var token = $('input[name="__RequestVerificationToken"]', form).val();

            $.ajax({
                type: 'POST',
                url: '/Subscription/Subscription/GenerateSmsCodeAsync',
                data: {
                    __RequestVerificationToken: token,
                    retry: true
                },
                error: function () {
                    $('#messageCode').show();
                    $('#messageCode').addClass('text-danger')
                    $('#messageCode').text('Une erreur est survenue lors de l\'envoie du code.')
                },
                success: function (data) {
                    if (data.success == 1) {
                        $('#lienResendCode').hide();
                        $('#messageCode').show();

                        $('#messageCode').addClass('text-success')
                        $('#messageCode').text('Votre code � �t� renvoy�.')
                    } else {
                        $('#messageCode').show();
                        $('#messageCode').addClass('text-danger')
                        $('#messageCode').text(data.message)
                    }
                }
            });
        });
    }

    EnableSubmitButton() {
        if (this.cgvIsChecked && this.ppdpIsChecked && this.cgctcapIsChecked) {
            $("#validate").removeAttr('disabled').removeClass('disabled');
        }
        else {
            $("#validate").attr('disabled', '').addClass('disabled');
        }
    };

}