export default class TextSlider extends HTMLElement {

  constructor() {
    super();
    this.$slides = this.querySelectorAll('.slide');
    this.$progresses = this.querySelectorAll('.progress');
    this.playNext = this.playNext.bind(this);
  }

  connectedCallback() {
    this.$progresses.forEach($progress => {
      $progress.addEventListener("animationend", this.playNext, false);
    })
    this.playNext();
  }

  playNext() {
    const current = this.querySelector('.progress.active');
    let next;

    if (current) {
      const currentIndex = Array.from(this.$progresses).indexOf(current);
      if (currentIndex < this.$progresses.length) {
        next = this.$progresses[currentIndex+1];
      }
      current.classList.remove('active');
      current.classList.add('passed');
    }

    if (!next) {
      this.$progresses.forEach($progress => {
        $progress.classList.remove('active');
        $progress.classList.remove('passed');
      })
      next = this.$progresses[0];
    }

    next.classList.add('active');

    this.showSlideAtIndex(Array.from(this.$progresses).indexOf(next));
  }

  showSlideAtIndex(index) {
    this.$slides.forEach(slide => {
      if (slide.classList.contains('passed')) {
        slide.classList.remove('passed');
      }
      if (slide.classList.contains('active')) {
        slide.classList.add('passed');
      }
      slide.classList.remove('active');
    });
    this.$slides[index].classList.add('active');
  }

}

