export default class Avis extends HTMLElement {

  constructor() {
      super();
      window.showAvis = this.showAvis;
  }

  connectedCallback() {
      $('.js-avis').on("click", function (e) {
          e.preventDefault();
          let index = $(e.currentTarget).data('target');
          $(`[data-id=short-${index}]`).hide();
          $(`[data-id=long-${index}]`).show();
      });

      //Tous les avis sont en display: none on les affiche via js avec le code suivant
      //ci dessous)
      $('.cta-more').on('click', function (e) {
          e.preventDefault();
          var max = $("#Avis_NbTotalCommentaires").val()
          var index = parseInt($('#Index').val())
          for (var i = index; i <= max; i++) {
              index = parseInt($('#Index').val())
              showAvis(index);
              $('#Index').val(parseInt($('#Index').val()) + parseInt(1));
          }

          $('#Index').val(parseInt(1))
          $('.cta-more').hide();
          $('.read-more-response').on("click", function (e) {
              e.preventDefault();
              let index = $(e.currentTarget).data('target');
              $(`[data-id=short-response-${index}]`).hide();
              $(`[data-id=long-response-${index}]`).show();
          });
          $('.read-more').on("click", function (e) {
              e.preventDefault();
              let index = $(e.currentTarget).data('target');
              $(`[data-id=short-${index}]`).hide();
              $(`[data-id=long-${index}]`).show();

          });
          // On refait le comportement sticky pour que le formulaire descende jusqu'en bas
          $(window).trigger('Sticky.redo-sticky');
      });

      //filtre des avis sur Marguerite en g�n�ral
      $('.ghost-select').on("change", function () {
          var url = "/Avis/SortListAvis"
          var typeSort = $(this).val();
          jQuery.ajax({
              type: 'POST',
              url: url,
              dataType: "html",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify({ typeSort: typeSort }),
              success: function (data) {
                  $(".review-cards").html(data);
                  for (var i = 0; i < 25; i++) {
                      let index = parseInt($('#Index').val())
                      showAvis(index);
                      $('#Index').val(parseInt($('#Index').val()) + parseInt(1));
                  };
                  $('#Index').val(parseInt(1))
              },
              error: function (data) {
              }
          });
      });



      $('.read-more-response').on("click", function (e) {
          e.preventDefault();
          let index = $(e.currentTarget).data('target');
          $(`[data-id=short-response-${index}]`).hide();
          $(`[data-id=long-response-${index}]`).show();
      });

      //On load les premiers avis lors de l'arriv� sur la page
      $(document).ready(function () {
          $(".comment-mobile").hide();
          for (var i = 0; i < 25; i++) {
              var index = parseInt($('#Index').val())
              showAvis(index);
              $('#Index').val(parseInt($('#Index').val()) + parseInt(1));
          }

          $('#Index').val(parseInt(1))
          $('.read-more-response').on("click", function (e) {
              e.preventDefault();
              let index = $(e.currentTarget).data('target');
              $(`[data-id=short-response-${index}]`).hide();
              $(`[data-id=long-response-${index}]`).show();
          });
          $('.read-more').on("click", function (e) {
              e.preventDefault();
              let index = $(e.currentTarget).data('target');
              $(`[data-id=short-${index}]`).hide();
              $(`[data-id=long-${index}]`).show();

          });

      });

    }

    showAvis(index) {
        var screenWidth = window.screen.availWidth;
        var tailleTextLongComment = $(`[data-id=long-${index}]`).html() != undefined ? $(`[data-id=long-${index}]`).html().trim().length : null;
        var tailleTextLongResponse = $(`[data-id=long-response-${index}]`).html() != undefined ? $(`[data-id=long-response-${index}]`).html().trim().length : null;

        //En fonction de la taille de l'�cran on affiche le commentaire long ou court et la version mobile
        if (screenWidth >= 720) {
            //pc
            if (tailleTextLongComment > 370) {
                $(`[data-id=short-${index}]`).show();
                $(`[data-id=long-${index}]`).hide();

            }
            else {
                $(`[data-id=short-${index}]`).hide();
                $(`[data-id=more-${index}]`).hide();
                $(`[data-id=long-${index}]`).show();
            }
            if (tailleTextLongResponse != null) {
                if (tailleTextLongResponse > 370) {
                    $(`[data-id=short-response-${index}]`).show();
                    $(`[data-id=long-response-${index}]`).hide();
                }
                else {
                    $(`[data-id=short-response-${index}]`).hide();
                    $(`[data-id=more-response-${index}]`).hide();
                    $(`[data-id=long-response-${index}]`).show();
                }
            }

        }
        else {
            //mobile
            var textToSend = $(`[data-id=short-mobile-${index}]`).html();
            var textToSendResponse = $(`[data-id=short-response-mobile-${index}]`).html();

            if (tailleTextLongComment > 143) {

                $(`[data-id=short-${index}]`).html(textToSend);
                $(`[data-id=short-${index}]`).show();
                $(`[data-id=long-${index}]`).hide();

            }
            else {
                $(`[data-id=short-${index}]`).hide();
                $(`[data-id=long-${index}]`).show();
            }
            if (tailleTextLongResponse != null) {
                if (tailleTextLongResponse > 143) {
                    $(`[data-id=short-response-${index}]`).html(textToSendResponse);
                    $(`[data-id=short-response-${index}]`).show();
                    $(`[data-id=long-response-${index}]`).hide();
                }
                else {
                    $(`[data-id=short-response-${index}]`).hide();
                    $(`[data-id=more-response-${index}]`).hide();
                    $(`[data-id=long-response-${index}]`).show();
                }
            }


        }
        //affiche l'avis
        $(".avis-" + index).each(function (index) {
            $(this).show();
        });
    };
}

