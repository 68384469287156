import YouTubePlayer from 'youtube-player';

export default class CardVideoFullwidth extends HTMLDivElement {

    constructor() {
        super();
        this.$videoPreview = this.querySelector('.js-video-preview');
        this.$videoPlaceholder = this.querySelector('.js-video-placeholder');
        this.videoId = this.dataset.videoId;
    }

    connectedCallback() {

        this.$videoPreview.addEventListener('click', () => {

            this.$videoPreview.style.display = 'none';
            this.$videoPlaceholder.style.display = 'block';

            this.loadVideo();
        });

    }

    loadVideo() {

        this.$videoPreview.style.display = 'none';
        this.$videoPlaceholder.style.display = 'block';

        const player = new YouTubePlayer(this.$videoPlaceholder, {
            videoId: this.videoId,
            playerVars: {
                autoplay: 1
            }
        });

    }

}