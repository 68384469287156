export default class Vote extends HTMLElement {

    constructor() {
        super();

    }

    connectedCallback() {
        window.handleVoteSuccess = this.handleVoteSuccess;
        window.handleVoteBegin = this.handleVoteBegin;
        window.handleVoteComplete = this.handleVoteComplete;

        $('#form_vote_abonne_oui').on("click", function (e) {
            $('#EstAbonne').val(true);
        });

        $('#form_vote_abonne_non').on("click", function (e) {
            $('#EstAbonne').val(false);
        });

        // au clic sur une carte de vote, on va remplir les infos du formulaire
        $('.js-vote').on("click", function (e) {
            let id = $(e.currentTarget).data('station-id');
            let name = $(e.currentTarget).data('station-name');
            let image = $(e.currentTarget).data('station-image');

            $('.js-vote-nom').text(name);
            $('.js-vote-image').attr("src", image);
            $('#QuartierId').val(id);
        });
    }

    // G�rer le succ�s du formulaire de vote
    handleVoteSuccess(data) {
        if (data.status == 1) {
            $('#linkVoteConfirm')[0].click();
        }
        else {
            $("#holderPartialForm").html(data);
        }
    }

    // Gerer la fin du formulaire de vote 
    handleVoteComplete() {
        $('#voteValidation')[0].disabled = false;
    }

    // Gerer le debut du formulaire de vote
    handleVoteBegin() {
        $('#voteValidation')[0].disabled = true;
    }
}

